import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×6\\@80% 1RM`}</p>
    <p>{`DB Rows 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`100ft Burpee Broad Jump Buy In`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`15-S2OH (135/95)`}</p>
    <p>{`15-T2B`}</p>
    <p>{`100ft Burpee Broad Jump Cash Out.  For time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`bonus wod`}{`*`}{`*`}</strong></p>
    <p>{`3 Rounds of:`}</p>
    <p>{`5 Wall Walks`}</p>
    <p>{`15 GHD Situps`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00 so bring a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Bootcamp today at 9:30.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      